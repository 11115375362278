<template>
  <v-row>
    <v-col cols="12">
      <v-toolbar class="px-0" color="transparent" flat height="55">
        <v-toolbar-title class="ml-n3 subtitle-1">
          Folios
          <v-icon class="mx-1" color="secondary" size="22">mdi-chevron-right</v-icon>
          <router-link :to="{ name: 'FoliosMonitor' }" class="text-decoration-none secondary--text font-weight-bold">Monitor</router-link>
          <v-icon class="mx-1" color="secondary" size="22">mdi-chevron-right</v-icon>
          Reporte POS
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-row align="center">
            <!-- by status filter -->
            <span class="mr-2 body-2 font-weight-medium">Filtrar por estado</span>
            <v-btn-toggle color="primary">
              <v-btn>Crítico (12)</v-btn>
              <v-btn>Alerta (6)</v-btn>
              <v-btn>Normal (86)</v-btn>
            </v-btn-toggle>
            <!-- end by status filter -->

            <v-query-builder class="ml-4" :query-map="filters" model="folios" />
          </v-row>
        </v-toolbar-items>
      </v-toolbar>
    </v-col>

    <v-col cols="12">
      <v-data-table :headers="headers" :items="posList" hide-default-header disable-sort mobile-breakpoint hide-default-footer @click:row="(items) => $router.push({ name: 'FoliosRetrieve', params: { id: foliosList.document_type, ...$route.params } }).catch(() => {})" style="cursor:pointer">
        <template v-slot:header="{ props: { headers } }">
          <VTableHeaders :headers="headers" />
        </template>

        <template v-slot:[`item.id`]="{item}">
          <code @click="toClipboard(d.id)" class="pa-1 font-weight-regular text--secondary background">{{item.id}}</code>
        </template>

        <template v-slot:[`item.internal_id`]="{item}">
          <code @click="toClipboard(d.id)" class="pa-1 font-weight-regular text--secondary background">{{item.internal_id}}</code>
        </template>

        <template v-slot:[`item.name`]="{item}">
          <span>{{item.name}} - {{item.address}}</span>
        </template>

        <template v-slot:[`item.status_bar`]="{item}">
          <v-progress-linear v-if="item.status >= 75" v-model="item.status" dark color="red lighten-1" height="8" rounded />
          <v-progress-linear v-else-if="item.status >= 50" v-model="item.status" dark color="orange lighten-1" height="8" rounded />
          <v-progress-linear v-else-if="item.status <= 25" v-model="item.status" dark color="green lighten-1" height="8" rounded />
        </template>

        <template v-slot:[`item.status`]="{item}">
          <v-chip v-if="item.status >= 75" color="red lighten-1" dark small>{{item.status}}% - Crítico</v-chip>
          <v-chip v-else-if="item.status >= 50" color="orange lighten-1" dark small>{{item.status}}% - Alerta</v-chip>
          <v-chip v-else-if="item.status <= 25" color="green lighten-1" dark small>{{item.status}}% - Normal</v-chip>
        </template>

        <template v-slot:[`item.actions`]="{item}">
          <v-btn outlined :to="{name: 'FoliosRetrieve', params: {id: item.id, ...$route.params}}">Ver detalle<v-icon right small>mdi-arrow-right</v-icon></v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import GenericViewMixin from '@/mixins/GenericViewMixin'
import documentTypesList from '@/collections/documentTypes'

export default {
  components: {
    VQueryBuilder: () => import('@/components/VQueryBuilder/VQueryBuilder'),
    VTableHeaders: () => import('@/components/VTable/VTableHeaders')
  },
  mixins: [
    GenericViewMixin
  ],
  data: () => ({
    count: 0,
    loading: false,
    documentTypes: documentTypesList.filter(d => d.country === 'CL'),
    headers: [
      { text: 'ID', value: 'id', width: 120 },
      { text: 'ID Interno', value: 'internal_id', width: 120 },
      { text: 'POS', value: 'name', sortable: true },
      { text: 'Riesgo operacional', value: 'status_bar', width: 220, sortable: true },
      { text: '', value: 'status', width: 100 },
      { text: '', value: 'actions', width: 120, align: 'end' }
    ],
    posList: [
      {
        id: 'pos_b8KeaZcVq9wVpX6CU2',
        status: 100,
        name: 'Pos 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'pos_45_tienda_54'
      },
      {
        id: 'pos_4EwNoJGfsBTjos8BsE',
        status: 99,
        name: 'Pos 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'pos_45_tienda_54'
      },
      {
        id: 'pos_4EwNoJGfsBTjos8BsE',
        status: 89,
        name: 'Pos 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'pos_45_tienda_54'
      },
      {
        id: 'pos_xhbMTDwcdcjM7a1S5M',
        status: 86,
        name: 'Pos 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'pos_45_tienda_54'
      },
      {
        id: 'pos_AxlEPRxHrVWtF3yJZI',
        status: 84,
        name: 'Pos 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'pos_45_tienda_54'
      },
      {
        id: 'pos_b8KeaZcVq9wVpX6CU2',
        status: 84,
        name: 'Pos 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'pos_45_tienda_54'
      },
      {
        id: 'pos_4EwNoJGfsBTjos8BsE',
        status: 79,
        name: 'Pos 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'pos_45_tienda_54'
      },
      {
        id: 'pos_xhbMTDwcdcjM7a1S5M',
        status: 78,
        name: 'Pos 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'pos_45_tienda_54'
      },
      {
        id: 'pos_xhbMTDwcdcjM7a1S5M',
        status: 78,
        name: 'Pos 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'pos_45_tienda_54'
      },
      {
        id: 'pos_xhbMTDwcdcjM7a1S5M',
        status: 76,
        name: 'Pos 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'pos_45_tienda_54'
      },
      {
        id: 'pos_xhbMTDwcdcjM7a1S5M',
        status: 75,
        name: 'Pos 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'pos_45_tienda_54'
      },
      {
        id: 'pos_AxlEPRxHrVWtF3yJZI',
        status: 48,
        name: 'Pos 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'pos_45_tienda_54'
      }
    ],
    filters: [
      {
        type: 'text',
        id: 'name',
        label: 'Nombre',
        value: null,
        operators: ['contiene']
      },
      {
        type: 'text',
        id: 'id',
        label: 'ID',
        value: null,
        operators: ['contiene']
      },
      {
        type: 'date',
        id: 'created',
        label: 'Fecha de creación',
        value: null,
        operators: ['es igual', 'es menor a', 'es mayor a']
      }
    ]
  }),
  created () {
    setTimeout(() => {
      this.loading = false
    }, 1000)
  }
}
</script>